import React from 'react'

export default function Footer() {
    return (
        <footer className="footer minimal bg-dark" >
            <div className="container">
                <div className="row">
                    <div className="col-md-4 col-md-offset-4">
                        {/* <div className="footer-logo local-scroll mb-30 wow zoomIn"
                            data-wow-duration="1s"
                            data-wow-delay="0.2s"
                        >
                            <h2>
                                <a href="#home" className="color-white">
                                    Unity
                                </a>
                            </h2>
                        </div>
                        <div className="socials footer-socials">
                            <Link href="#">
                                <i className="fa fa-facebook" />
                            </Link>
                            <Link href="#">
                                <i className="fa fa-twitter" />
                            </Link>
                            <Link href="#">
                                <i className="fa fa-google-plus" />
                            </Link>
                            <Link href="#">
                                <i className="fa fa-linkedin" />
                            </Link>
                            <Link href="#">
                                <i className="fa fa-behance" />
                            </Link>
                        </div> */}
                        {/* end socials */}
                        <span className="copyright text-center">
                            Copyright© 2023 UNITYcompanies
                        </span>
                    </div>
                </div>
            </div>
        </footer>
    )
}
