const { createSlice } = require('@reduxjs/toolkit');

const authSlice = createSlice({
    name: 'cookies',
    initialState: {
        isAcceptCookies: false
    },
    reducers: {
        setIsCookies(state, action) {
            state.isAcceptCookies = action.payload.isAcceptCookies;
        }
    },
});

export const { setIsCookies } = authSlice.actions;
export default authSlice.reducer;
