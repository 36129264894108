import { Suspense } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import Layout from './layout/index.tsx';
import { WebRoutes } from './routes';
import Authmiddleware from './routes/route';
import Loader from './utils/Loader.tsx';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          <Route path='/' element={<Layout />}>
            {WebRoutes.map((e, i) => (
              <Route
                path={e.path}
                key={i}
                element={
                  <Authmiddleware>
                    {e.component}
                  </Authmiddleware>
                } />
            ))}
          </Route>
        </Routes>
      </Router>
    </Suspense>
  );
}

export default App;
