import React from 'react';
import { Navigate } from 'react-router-dom';

const Authmiddleware = ({ children, isProtected }) => {
  const auth = localStorage.getItem('access_token');

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (!isProtected) {
    return children;
  } else {
    return auth.isAuthenticate ? children : <Navigate to="/login" />;
  }
}

export default Authmiddleware;