import React, { lazy } from 'react';
//@ts-ignore
const Home = lazy(() => import('../pages/home.tsx'));
//@ts-ignore
const Community = lazy(() => import('../pages/community.tsx'));
//@ts-ignore
const Universe = lazy(() => import('../pages/universe.tsx'));
//@ts-ignore
const Studio = lazy(() => import('../pages/studio.tsx'));
//@ts-ignore
const OurTeam = lazy(() => import('../pages/ourTeam.tsx'));
//@ts-ignore
const Page404 = lazy(() => import('../pages/404.tsx'));
//@ts-ignore
const LogIn = lazy(() => import('../pages/auth/logIn.tsx'));
//@ts-ignore
const SignUp = lazy(() => import('../pages/auth/signUp.tsx'));

// export const AuthRoutes = [
//     { path: '/login', component: <LogIn />, isProtected: false },
//     { path: '/signup', component: <SignUp />, isProtected: false },
// ];

export const WebRoutes = [
    { path: '/', component: <Home />, isProtected: false },
    { path: '/community', component: <Community />, isProtected: false },
    { path: '/technology', component: <Universe />, isProtected: false },
    { path: '/studio', component: <Studio />, isProtected: false },
    { path: '/our-team', component: <OurTeam />, isProtected: false },
    { path: '/login/:service', component: <LogIn />, isProtected: false },
    { path: '/signup/:service', component: <SignUp />, isProtected: false },
    { path: '*', component: <Page404 />, isProtected: false },
];