import Cookies from 'js-cookie';
import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Outlet } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";
import { toast } from 'react-toastify';
// @ts-ignore
import Footer from './footer.tsx';
// @ts-ignore
import Header from './header.tsx';
import { PopupWidget } from 'react-calendly';

export default function Layout() {
    const onAccept = (e: any) => {
        if (e) {
            // triggered if user scrolls past threshold
            Cookies.set('acceptCookies', false);
        } else {
            toast.success("Cookies Accepted");
            Cookies.set('acceptCookies', true, { expires: 365 });
        }
    }
    return (
        <div className="main-wrapper-onepage main oh">
            <Header />
            <Outlet />
            <CookieConsent
                location="bottom"
                style={{ background: "#2B373B" }}
                buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
                onAccept={(acceptedByScrolling) => onAccept(acceptedByScrolling)}>
                This website uses cookies to enhance the user experience.
            </CookieConsent>
            <ScrollToTop
                smooth
                width={"20"}
                height={"20"}
                style={{ borderRadius: '50%', bottom: '65px' }}
            />
            <PopupWidget
                url="https://calendly.com/jawadahmedhnh"
                /*
                 * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                 * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                 */
                rootElement={document.getElementById("root")}
                text="Click here to schedule!"
                // textColor="#ffffff"
                // color="#00a2ff"
            />
            <Footer />
        </div>
    )
}
