import React, { useState } from 'react'
import { Link } from 'react-router-dom';

export default function Header() {
    const [MenuOpen, setMenuOpen] = useState<Boolean>();

    const handleMenuToggle = () => {
        setMenuOpen(!MenuOpen);
    }

    const handleMenuClose = () => {
        setMenuOpen(false);
    }

    return (
        <header className="nav-type-3">
            <div className={MenuOpen ? "fs-menu open" : "fs-menu"} id="overlay">
                <nav className="overlay-menu">
                    <ul>
                        <li className="active">
                            <Link to="/" onClick={handleMenuClose}>Home</Link>
                        </li>
                        <li>
                            <Link to="/community" onClick={handleMenuClose}>Community</Link>
                        </li>
                        <li>
                            <Link to="/studio" onClick={handleMenuClose}>Studio</Link>
                        </li>
                        <li>
                            <Link to="/technology" onClick={handleMenuClose}>Technology</Link>
                        </li>
                        <li>
                            <Link to="/our-team" onClick={handleMenuClose}>Our Team</Link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div className="container-fluid nav-wrap clearfix">
                <div className="logo-container">
                    <Link to="/" className="logo">
                        <img className="logo" src="/assets/img/community_logo.png" width={'170'} alt="logo" />
                    </Link>
                </div>
                <div id="nav-icon" onClick={handleMenuToggle} className={MenuOpen && 'open'}>
                    <span />
                    <span />
                    <span />
                    <span />
                </div>
            </div>{" "}
            {/* end container */}
        </header>
    )
}
